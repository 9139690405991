<template>
  <div>

    <div>
      <vs-table max-items="30" pagination :data="users">
        <template slot="header">

        </template>
        <template slot="thead">
          <vs-th>
            Metric
          </vs-th>
          <vs-th>
            Definition
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].name">
              {{data[indextr].name}}
            </vs-td>

            <vs-td :data="data[indextr].desc">
              {{data[indextr].desc}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

  </div>
</template>


<style scoped>
.chart {
  height: 100vh;
}
body {
  margin: 0;
}
</style>

<script>

import Vue from 'vue'
import DateRangePicker from 'vue-mj-daterangepicker'
import 'vue-mj-daterangepicker/dist/vue-mj-daterangepicker.css'
// cust: https://vuejsexamples.com/vue-js-date-range-picker-with-multiples-ranges-and-presets/

Vue.use(DateRangePicker)
import FxModal from './new-dash/blocks/Modal'


//core
import {use} from "echarts/core";

//render
import {CanvasRenderer} from "echarts/renderers";

//chart
import {PieChart, SunburstChart, LineChart} from "echarts/charts";

//component
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
} from "echarts/components";

import VChart, {THEME_KEY} from "vue-echarts";

//install
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  SunburstChart,
  GridComponent,
  LineChart,
  ToolboxComponent
]);


import VueApexCharts from "vue-apexcharts";
import apexChatData from "./apexChartData.js";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import "flatpickr/dist/flatpickr.css";
import flatPickr from "vue-flatpickr-component";
import moment from 'moment'



export default {
  data() {

    return {
      users:[
        {
          "id": 1,
          "name": "CTI (Click-to-Install)",
          "desc": "Number of users who downloaded the app",
        },
        {
          "id": 2,
          "name": "DAU/MAU %",
          "desc": "This will show app stickiness",
        },
        {
          "id": 3,
          "name": "Daily Active User (DAU)",
          "desc": "Number of unique users who performed an action on the app. See list of Actions",
        },
        {
          "id": 4,
          "name": "Monthly Active User (DAU)",
          "desc": "Number of unique users who performed an action on the app in that month. See list of Actions",
        },

      ]
    }
  },

  components: {
    VueApexCharts,
    StatisticsCardLine,
    flatPickr,
    // 'v-chart': ECharts
    // ECharts
    VChart,
    FxModal
  },

  provide: {
    [THEME_KEY]: "light"
  },

  computed: {

  },

  mounted() {

  },

  methods: {

  },

  created() {
    this.$store.dispatch('dashboard/initializeDashboard2', {

      org_id: this.$store.state.AppActiveUser.org_id,
      date_from: this.date_from,
      date_to: this.date_to,

    }).then((data)=>{

      this.category_option.series.data = data.insights.interest_areas
      this.is_loading = false
      this.$vs.loading.close();

    })

  },
};
</script>
<style>
.btn_dashboard {
  float: right;
  position: relative;
  left: 43px;
  top: -3px;
}
</style>
<style>
.tab_heading_item {
  font-weight: bold;
  width: 100%;
  text-align: center;

}

.tab_heading_item_parent {
  background: white;
  cursor: pointer;
}

.tab_heading_active {

  background: #1c54a2;
  padding: 10px;
  color: white;
  display: inline-block;
  border-radius: 10px;
}

.tab_heading_container {
  margin-bottom: 20px;
  padding: 5px;
  background: #ffffff;
}

.title_range {
  color: silver;
}
</style>
